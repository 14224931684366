<template>
  <div>
    <div class="d-flex flex-row mb-2">
      <p class="text-2xl mb-2">Liste des Vues de Partenaires</p>
      <v-col class="text-right">
        <v-btn color="primary" :to="{ name: 'admin-edit-partner-view' }">
          <v-icon class="mr-3">{{ icons.mdiPencilBox }}</v-icon
          >Ajouter une nouvelle vue</v-btn
        >
      </v-col>
    </div>
    <v-card>
      <v-card-title class="align-start">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche... "
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :page="options.page"
          :pageCount="numberOfPages"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="options.total"
          :loading="loading"
          :sort-by="'ca'"
          :sort-desc="true"
          class="elevation-1"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:item.actions="{ item }">
            <a target="_blank">
              <v-btn
                :to="`/admin/edit-partner-view/${item.id}`"
                class="mx-2"
                elevation="0"
                fab
                small
              >
                <v-icon color="primary">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                elevation="0"
                fab
                small
                @click.native="
                  dialogModel.dialog = !dialogModel.dialog;
                  dialogModel.id = item.id;
                  dialogModel.name = item.label;
                "
              >
                <v-icon color="secondary">
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </v-btn>
            </a>
          </template>
        </v-data-table>
        <p>Note: les filtres sont ajoutés à la requête avec un "ET"</p>
      </v-card-text>
      <v-dialog v-model="dialogModel.dialog" width="70vw">
        <del-confirmation
          :dialogOpen="dialogModel.dialog"
          :id="dialogModel.id"
          :name="dialogModel.name"
          @openDialog="openDialog"
        ></del-confirmation>
      </v-dialog>
    </v-card>
  </div>
</template>
<style scoped></style>
<script>
import axios from "@axios";
import debounce from "lodash/debounce";
import { mdiPencil, mdiTrashCan } from "@mdi/js";

import DelConfirmation from "@/components/common/modals/PartnerViewDelConfirmation";

export default {
  name: "PartnerViewList",
  components: { DelConfirmation },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      headers: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Label",
          value: "label",
        },
        {
          text: "Dimension Types",
          value: "dimension_types",
        },
        {
          text: "Nature",
          value: "nature",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      dialogModel: {
        dialog: false,
        id: -1,
        name: "",
      },
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
  mounted() {
    this.debouncedUpdateData();

    this.$store.dispatch("admin/getPartnerNatureList");
  },
  methods: {
    formatResult(data) {
      return {
        ...data,
        nature:
          this.natures.find((nature) => nature.id === parseInt(data.nature_id))
            ?.name ?? "",
      };
    },
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        page: page - 1,
        limit: itemsPerPage,
        ...(this.search ? { search: this.search } : {}),
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };

      const { data } = await axios.get("/partner-views/detailed", {
        params: queryParams,
      });

      this.loading = false;
      this.items = data.items.map((item) => this.formatResult(item));
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    openDialog(value) {
      this.dialogModel.dialog = value;
      this.updateData();
    },
  },
  computed: {
    natures() {
      return this.$store.getters["admin/getPartnerNatureList"];
    },
  },
  watch: {
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.debouncedUpdateData();
    },
    "options.page"() {
      this.debouncedUpdateData();
    },
    "options.sortBy"() {
      this.debouncedUpdateData();
    },
    "options.sortDesc"() {
      this.debouncedUpdateData();
    },
    search() {
      this.options.page = 1;
      this.debouncedUpdateData(); // Automatic with watch
    },
  },
};
</script>
